// src/Footer.js
import React from 'react';
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaMapMarkedAlt, FaMapMarkerAlt, FaPhone, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';

const Footer = () => {
    return (

        <footer class="bg-charcoal text-silver py-10  ">
            <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div class="md:flex md:justify-between">
                    <div class="mb-6 md:mb-0 ">
                        <h2 className="text-4xl font-semibold custom-gradient">SpaceMaster Construction</h2>
                        <p className="mt-2 text-sm text-gunmetal">
                            Transforming Spaces, Building Dreams
                        </p>
                    </div>
                    <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-white uppercase ">Resources</h2>
                            <ul class="text-silver  font-medium">
                                <li class="mb-4">
                                    <a href="#" class="hover:text-gunmetal" aria-label="Flowbite homepage">Home</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:text-gunmetal" aria-label="Flowbite homepage">Our Projects</a>
                                </li>

                                <li>
                                    <a href="#" class="hover:text-gunmetal" aria-label="spacemaster CSS homepage">Floor Plans</a>
                                </li>
                            </ul>
                        </div>


                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-white uppercase ">Legal</h2>
                            <ul class="text-silver  font-medium">
                                <li class="mb-4">
                                    <a href="#" class="hover:text-gunmetal" aria-label="View our Privacy Policy">Privacy Policy</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:text-gunmetal" aria-label="View our Privacy Policy">Referral Policy</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:text-gunmetal" aria-label="View our Privacy Policy">FAQs</a>
                                </li>
                                <li>
                                    <a href="#" class="hover:text-gunmetal" aria-label="View our Terms & Conditions">Terms &amp; Conditions</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-white uppercase ">Get in Touch</h2>
                            <ul className="text-silver  font-medium">
                                <li className="mb-4 flex items-center">
                                    <FaMapMarkerAlt className="mr-2" />
                                    <span>Banglore,Karnataka,India</span>
                                </li>
                                <li className="mb-4 flex items-center">
                                    <FaEnvelope className="mr-2" />
                                    <a href="mailto:info@example.com" className="hover:text-gunmetal" aria-label="Email us">info@example.com</a>
                                </li>
                                <li className="flex items-center">
                                    <FaPhone className="mr-2" />
                                    <a href="tel:+1234567890" className="hover:text-gunmetal" aria-label="Call us">+1 (234) 567-890</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr class="my-6 border-gunmetal sm:mx-auto lg:my-8" />
                <div class="sm:flex sm:items-center sm:justify-between">
                    <div className="text-sm text-gunmetal text-center mt-6">
                        &copy; {new Date().getFullYear()} SpaceMaster Construction. All rights reserved.
                        <div className="mt-2">
                            Designed by <span className="font-normal custom-gradient">Streakify Technologies Pvt. Ltd.</span>
                        </div>
                    </div>
                    <div className="flex justify-center space-x-6 mt-6">

                        <a href="https://facebook.com" aria-label="Facebook" className="text-silver hover:text-white">
                            <FaFacebook size={24} />
                        </a>
                        <a href="https://instagram.com" aria-label="Instagram" className="text-silver hover:text-white">
                            <FaInstagram size={24} />
                        </a>
                        <a href="https://linkedin.com" aria-label="LinkedIn" className="text-silver hover:text-white">
                            <FaLinkedin size={24} />
                        </a>
                        <a href="https://linkedin.com" aria-label="LinkedIn" className="text-silver hover:text-white">
                            <FaWhatsapp size={24} />
                        </a>
                        <a href="https://linkedin.com" aria-label="LinkedIn" className="text-silver hover:text-white">
                            <FaTwitter size={24} />
                        </a>
                        <a href="https://linkedin.com" aria-label="LinkedIn" className="text-silver hover:text-white">
                            <FaYoutube size={24} />
                        </a>

                    </div>
                </div>
            </div>
            <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
                <div
                    className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#4B4B4B] to-[#4B4B4B] opacity-40"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                ></div>
            </div>
        </footer >


    );
};

export default Footer;
