// src/Home.js
import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowRight, FaBuilding, FaChevronDown, FaChevronUp, FaClock, FaFastBackward, FaFastForward, FaHammer, FaLeaf, FaPlus, FaRProject, FaUserFriends } from 'react-icons/fa';

const Home = () => {

    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleFAQ = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
    const [location, setLocation] = useState('BANGALORE');

    const packages = [
        {
            name: 'Essential Package',
            price: '2,190',
            features: ['Architectural Layout | 2D', 'Basic Elevation', 'Structural Design', '3D Elevation', 'Electrical Drawings', 'Plumbing Drawings', 'Furniture Plan']
        },
        {
            name: 'Premium Package',
            price: '2,790',
            features: ['2D Floor Plan', 'Architectural Layout | 2D', 'Structural Design', '3D Elevation', 'Electrical Drawings', 'Plumbing Drawings', 'Furniture Plan']
        },
        {
            name: 'Luxury Package',
            price: '3,190',
            features: ['Architectural Layout | 2D', 'Basic Elevation', 'Structural Design', '3D Elevation', 'Electrical Drawings', 'Plumbing Drawings', 'Furniture Plan']
        }
    ];

    const sections = [
        { title: 'Structure', expanded: false },
        { title: 'Kitchen', expanded: false },
        { title: 'Bathroom', expanded: false },
        { title: 'Doors & Windows', expanded: false },
        { title: 'Painting', expanded: false },
        { title: 'Flooring', expanded: false },
        { title: 'Electrical', expanded: false },
        { title: 'Miscellaneous', expanded: false },
        { title: 'Luxury Indulgences for the Space Enthusiast', expanded: false },
        { title: 'Home Automation including safety & security', expanded: false }
    ];
    const faqs = [
        {
            question: "What services do you offer?",
            answer: "We offer a wide range of services including residential construction, commercial construction, renovations, and project management. Our team is equipped to handle all aspects of construction from design to completion."
        },
        {
            question: "How long will my construction project take?",
            answer: "The duration of a construction project varies depending on its complexity, size, and scope. We provide a detailed timeline during the initial consultation to help you understand the process."
        },
        {
            question: "What is the estimated cost of a construction project?",
            answer: "The cost of a construction project can vary widely based on materials, labor, and design choices. We offer free estimates after assessing your project requirements during the consultation."
        },
        {
            question: "Do you offer warranties on your work?",
            answer: "Yes, we provide warranties on our work to ensure that you are satisfied with the results. The specifics of the warranty will be discussed during the contract phase."
        },
        {
            question: "Can I make changes to the design after construction begins?",
            answer: "While it is possible to make changes during construction, it may impact the timeline and budget. We encourage discussing any desired changes with our project manager as soon as possible."
        },
        {
            question: "What should I do if I have a concern during the construction process?",
            answer: "If you have any concerns during the construction process, please reach out to your assigned project manager immediately. We are committed to resolving issues quickly and effectively."
        }
    ];
    const projects = [
        {
            id: 'CRN001',
            location: 'Rajarajeshwari Nagar, Bengaluru',
            renderImage: '/assets/images/light.png',

        },
        {
            id: 'CRN002',
            location: 'Bannerghatta Main Road, Bengaluru',
            renderImage: '/assets/images/green.png',

        },

        {
            id: 'CRN004',
            location: 'Rajarajeshwari Nagar, Bengaluru',
            renderImage: '/assets/images/light.png',

        },
        {
            id: 'CRN005',
            location: 'Bannerghatta Main Road, Bengaluru',
            renderImage: '/assets/images/green.png',

        },
        {
            id: 'CRN003',
            location: 'Jigani, Bengaluru',
            renderImage: '/assets/images/img2.png',

        },
        // Add more project data as needed
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };
    return (
        <div className="bg-darkCoffee text-graphite  font-sans">
            {/* Hero Section */}
            <section className="relative bg-darkCoffee text-white h-screen flex items-center justify-between px-6 md:px-10">
                {/* Left Content */}
                <div className="text-center w-full md:w-2/5 mt-32 lg:mt-32 md:mt-0"> {/* Adjusted margin-top for spacing */}
                    <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-4 leading-tight text-white custom-gradient">
                        SpaceMaster Construction
                    </h1>
                    <p className="text-2xl text-silver  mb-8">
                        Transforming Spaces, Building Dreams
                    </p>

                </div>

                {/* Right Image */}
                <div className="hidden md:block w-2/5  lg:mt-32 md:mt-0"> {/* Increased margin-top for spacing */}
                    <img
                        src="/assets/images/img1.png"
                        alt="Construction"
                        className="w-full h-auto object-cover transition-transform transform hover:scale-105 hover:opacity-80"
                    />
                </div>

            </section>
            <div class="h-screen bg-darkCoffee py-16">
                <div class="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
                    <div class="col-span-2 mb-8">
                        <p class="text-lg font-medium text-custom-gradient mb-7">Building the Future</p>
                        <h2 className="text-3xl sm:text-5xl font-extrabold lg:text-left  custom-gradient font-bold mb-4 leading-tight">
                            Space Master Constructions
                        </h2>
                        <p class="font-light text-silver text:md ">
                            With over 20 years of industry experience, Space Master Constructions has been at the forefront of innovative building solutions, specializing in residential, commercial, and sustainable projects.
                        </p>
                        <div class="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                            <div>
                                <a href="#"
                                    class="inline-flex items-center text-base font-medium text-silver hover:text-white ">
                                    Explore Our Projects
                                    <svg class="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </a>
                            </div>
                            <div>
                                <a href="#"
                                    class="inline-flex items-center text-base font-medium text-silver hover:text-white ">
                                    Contact Us for Inquiries
                                    <svg class="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
                        <div>
                            <svg class="w-10 h-10 mb-2 text-gunmetal md:w-12 md:h-12 " fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <h3 class="mb-2 text-2xl font-bold dark:text-white">Over 500 Projects Completed</h3>
                            <p class="font-light text-gray-500 ">Delivering quality and excellence in every project.</p>
                        </div>
                        <div>
                            <svg class="w-10 h-10 mb-2 text-gunmetal md:w-12 md:h-12 " fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z">
                                </path>
                            </svg>
                            <h3 class="mb-2 text-2xl font-bold dark:text-white">20+ Years of Experience</h3>
                            <p class="font-light text-gray-500 ">Leading the way in construction innovation.</p>
                        </div>
                        <div>
                            <svg class="w-10 h-10 mb-2 text-gunmetal md:w-12 md:h-12 " fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <h3 class="mb-2 text-2xl font-bold dark:text-white">99% Client Satisfaction Rate</h3>
                            <p class="font-light text-gray-500 ">Dedicated to ensuring client satisfaction in every project.</p>
                        </div>
                        <div>
                            <svg class="w-10 h-10 mb-2 text-gunmetal md:w-12 md:h-12 " fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
                            </svg>
                            <h3 class="mb-2 text-2xl font-bold dark:text-white">100+ Sustainable Projects</h3>
                            <p class="font-light text-gray-500 text-md ">Leading the charge in eco-friendly construction.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-darkCoffee py-16 sm:py-24">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 lg:max-w-none lg:grid-cols-2 gap-x-8 gap-y-16">
                        {/* Left Section */}
                        <div className="max-w-xl lg:max-w-lg flex flex-col justify-center">
                            <h2 className="text-3xl sm:text-5xl font-extrabold lg:text-left text-center custom-gradient mb-6">
                                About Us
                            </h2>
                            <p className="mt-4 text-md text-gray-300">
                                At SpaceMaster Construction, we believe that every project tells a story. Founded on the principles of integrity and innovation, our team of passionate builders and designers is dedicated to turning visions into reality. With state-of-the-art technology and a commitment to sustainability, we ensure that every structure not only meets but exceeds our clients’ expectations.
                            </p>

                            <div class="mt-6 space-y-10 text-left text-silver">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <div class="flex items-center justify-center w-22 h-12 rounded-md">
                                            <FaBuilding />
                                        </div>
                                    </div>
                                    <div class="ml-4">
                                        <h4 class="text-lg font-medium leadi text-white">Innovative Construction Techniques</h4>
                                        <p class="mt-2 text-sm">Explore cutting-edge construction techniques that improve efficiency, safety, and sustainability in building projects.</p>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <div class="flex items-center justify-center w-22 h-10 rounded-md">
                                            <FaRProject />
                                        </div>
                                    </div>
                                    <div class="ml-4">
                                        <h4 class="text-lg font-medium leadi text-white">Comprehensive Project Management</h4>
                                        <p class="mt-2 text-sm">Utilize our robust project management solutions that streamline workflows and ensure timely project delivery.</p>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <div class="flex items-center justify-center w-22 h-12 rounded-md">
                                            <FaFastForward />
                                        </div>
                                    </div>
                                    <div class="ml-4">
                                        <h4 class="text-lg font-medium leadi text-white">Quality Assurance Standards</h4>
                                        <p class="mt-2 text-sm">Our commitment to quality assurance ensures that every project meets the highest standards of safety and excellence.</p>
                                    </div>
                                </div>

                            </div>

                            {/* Call to Action Buttons */}
                            <div className="mt-6 flex max-w-md gap-x-4">
                                <button
                                    type="submit"
                                    className="flex-none rounded-md bg-graphite px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-silver focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                >
                                    Explore
                                </button>
                                <button
                                    type="button"
                                    className="flex-none rounded-md bg-silver px-3.5 py-2.5 text-sm font-semibold text-darkCoffee shadow-sm hover:bg-gray-400"
                                >
                                    Contact Us
                                </button>
                            </div>
                        </div>

                        {/* Right Section */}
                        <div className="flex items-center justify-center">
                            <img
                                src="/assets/images/light.png"
                                alt="SpaceMaster Construction"
                                className="w-full h-auto max-w-lg object-cover rounded-lg " // Changed max-w-sm to max-w-lg
                            />
                        </div>

                    </div>



                </div>
            </div>


            <section class="bg-darkCoffee py-20">
                <div class="max-w-screen-xl mx-auto px-4 sm:px-5 lg:px-8">
                    <div class="text-center mb-12">
                        <h2 class="text-3xl sm:text-5xl font-extrabold custom-gradient font-bold mb-4 leading-tight text-left">
                            Why Choose Us
                        </h2>
                        <p class="mt-6 text-base leading-6 text-silver text-center lg:text-left">
                            Discover the unique qualities that set us apart and make us the right choice for your needs.
                        </p>
                    </div>
                    <div class="flex flex-wrap justify-center">
                        <div class="p-4 md:w-1/4 sm:w-1/2">
                            <div class="bg-graphite p-4 rounded transform transition duration-500 hover:scale-110">
                                <div class="flex justify-center mb-3">
                                    <img
                                        src="https://image3.jdomni.in/banner/13062021/58/97/7C/E53960D1295621EFCB5B13F335_1623567851299.png?output-format=webp"
                                        class="w-24 filter grayscale brightness-150"
                                        alt="State-of-the-Art Equipment"
                                    />
                                </div>
                                <h2 class="font-regular text-xl text-white">State-of-the-Art Equipment</h2>
                                <p class="mt-2 text-sm text-gray-300">We use the latest construction machinery to ensure quality and efficiency in every project.</p>
                            </div>
                        </div>

                        <div class="p-4 md:w-1/4 sm:w-1/2">
                            <div class="bg-graphite p-4 rounded transform transition duration-500 hover:scale-110">
                                <div class="flex justify-center mb-3">
                                    <img
                                        src="https://image2.jdomni.in/banner/13062021/3E/57/E8/1D6E23DD7E12571705CAC761E7_1623567977295.png?output-format=webp"
                                        class="w-24 filter grayscale brightness-150"
                                        alt="Competitive Pricing"
                                    />
                                </div>
                                <h2 class="font-regular text-xl text-white">Competitive Pricing</h2>
                                <p class="mt-2 text-sm text-gray-300">We offer transparent and reasonable rates without compromising on quality and schedule.</p>
                            </div>
                        </div>

                        <div class="p-4 md:w-1/4 sm:w-1/2">
                            <div class="bg-graphite p-4 rounded transform transition duration-500 hover:scale-110">
                                <div class="flex justify-center mb-3">
                                    <img
                                        src="https://image3.jdomni.in/banner/13062021/16/7E/7E/5A9920439E52EF309F27B43EEB_1623568010437.png?output-format=webp"
                                        class="w-24 filter grayscale brightness-150"
                                        alt="Timely Project Delivery"
                                    />
                                </div>
                                <h2 class="font-regular text-xl text-white">Timely Project Delivery</h2>
                                <p class="mt-2 text-sm text-gray-300">We value your time and commit to delivering projects on schedule, every time.projects on schedule, every time.</p>
                            </div>
                        </div>

                        <div class="p-4 md:w-1/4 sm:w-1/2">
                            <div class="bg-graphite p-4 rounded transform transition duration-500 hover:scale-110">
                                <div class="flex justify-center mb-3">
                                    <img
                                        src="https://image3.jdomni.in/banner/13062021/EB/99/EE/8B46027500E987A5142ECC1CE1_1623567959360.png?output-format=webp"
                                        class="w-24 filter grayscale brightness-150"
                                        alt="Industry Expertise"
                                    />
                                </div>
                                <h2 class="font-regular text-xl text-white">Industry Expertise</h2>
                                <p class="mt-2 text-sm text-gray-300">Our team of seasoned professionals brings years of experience in the construction industry.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-darkCoffee py-16">
                <div className="max-w-screen-xl mx-auto px-4 sm:px-5 lg:px-8 flex flex-col justify-between">
                    <div className="text-center mt-16">
                        <h2 className="text-3xl mt-4 sm:text-5xl font-extrabold lg:text-left text-center custom-gradient font-bold mb-4 leading-tight">
                            Our Projects
                        </h2>
                        <p className="mt-2 text-base leading-6 text-silver text-center lg:text-left">
                            Discover our latest projects and how we're transforming ideas into impactful solutions across various industries.
                        </p>

                        <Slider {...{ ...settings, slidesToShow: 3 }}> {/* Show 3 slides */}
                            {projects.map((project) => (
                                <div key={project.id} className="px-4 py-6 mb-10"> {/* Adjusted padding and margin */}
                                    <div className="flex flex-col items-center">
                                        <div className="relative w-full">
                                            <img
                                                src={project.renderImage}
                                                alt={project.id}
                                                className="w-full h-auto object-cover rounded-lg" // Ensures full width and maintains aspect ratio
                                            />
                                        </div>
                                        <h3 className="text-2xl font-bold mb-1 text-center">{project.id}</h3> {/* Reduced margin-bottom */}
                                        <p className="mb-2 text-center text-silver">{project.location}</p> {/* Reduced margin-bottom */}
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>






            <section class="bg-darkCoffee py-20 h-screen ">
                <div class="max-w-screen-xl mx-auto px-4 sm:px-5 lg:px-8 flex flex-col justify-between">
                    <div class="text-center">

                        <h3 class="text-3xl sm:text-5xl leading-normal font-extrabold tracking-tight 
            lg:text-left text-center custom-gradient">
                            How it <span class="custom-gradient">Works?</span>
                        </h3>
                        <p class="mt-6 text-base leading-6 text-silver text-center lg:text-left">
                            A brief description of how the process works to give context to the reader.
                        </p>
                    </div>

                    <div class="mt-20">
                        <ul class="md:grid md:grid-cols-5 md:col-gap-10 md:row-gap-15">
                            <li class="bg-graphite p-5 pb-10 text-center">
                                <div class="flex flex-col items-center">
                                    <div class="flex-shrink-0 relative top-0 -mt-16">
                                        <div class="flex items-center justify-center h-20 w-20 rounded-full bg-silver text-darkCoffee border-4 border-graphite text-xl font-semibold">
                                            1
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <h4 class="text-lg leading-6 font-semibold text-white">Raise a Request</h4>
                                        <p class="mt-2 text-base leading-6 text-silver">
                                            Start by submitting your request to communicate your needs clearly.


                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="bg-graphite p-5 pb-10 text-center">
                                <div class="flex flex-col items-center">
                                    <div class="flex-shrink-0 relative top-0 -mt-16">
                                        <div class="flex items-center justify-center h-20 w-20 rounded-full bg-silver text-darkCoffee border-4 border-graphite text-xl font-semibold">
                                            2
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <h4 class="text-lg leading-6 font-semibold text-white">Talk to an Expert</h4>
                                        <p class="mt-2 text-base leading-6 text-silver">
                                            Connect with our experts for guidance and tailored insights.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="bg-graphite p-5 pb-10 text-center">
                                <div class="flex flex-col items-center">
                                    <div class="flex-shrink-0 relative top-0 -mt-16">
                                        <div class="flex items-center justify-center h-20 w-20 rounded-full bg-silver text-darkCoffee border-4 border-graphite text-xl font-semibold">
                                            3
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <h4 class="text-lg leading-6 font-semibold text-white">Schedule a Meeting</h4>
                                        <p class="mt-2 text-base leading-6 text-silver">
                                            Arrange a meeting to discuss your vision and align ideas.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="bg-graphite p-5 pb-10 text-center">
                                <div class="flex flex-col items-center">
                                    <div class="flex-shrink-0 relative top-0 -mt-16">
                                        <div class="flex items-center justify-center h-20 w-20 rounded-full bg-silver text-darkCoffee border-4 border-graphite text-xl font-semibold">
                                            4
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <h4 class="text-lg leading-6 font-semibold text-white">Get Your Designs</h4>
                                        <p class="mt-2 text-base leading-6 text-silver">
                                            Receive customized design proposals that reflect your preferences.


                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="bg-graphite p-5 pb-10 text-center">
                                <div class="flex flex-col items-center">
                                    <div class="flex-shrink-0 relative top-0 -mt-16">
                                        <div class="flex items-center justify-center h-20 w-20 rounded-full bg-silver text-darkCoffee border-4 border-graphite text-xl font-semibold">
                                            5
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <h4 class="text-lg leading-6 font-semibold text-white">Settle In</h4>
                                        <p class="mt-2 text-base leading-6 text-silver">
                                            Move into your new home and enjoy the results of your efforts!
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className=" bg-darkCoffee py-16 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    {/* Header */}
                    <div className="text-center mb-12">
                        <h2 className="text-3xl sm:text-5xl leading-normal font-extrabold tracking-tight 
            lg:text-left text-center custom-gradient mb-6 text-left ml-6"> Construction Packages</h2>
                        <p className="text-gray-300 text-left ml-6">Find the best Construction Packages*</p>
                        <div className="mt-4 flex items-left justify-start gap-2 ml-6">
                            <span className="text-gray-300 ">Currently showing for</span>
                            <select
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                className="bg-graphite border border-gray-600 rounded-md px-3 py-1 text-white focus:outline-none focus:border-gray-400"
                            >
                                <option value="BANGALORE">BANGALORE</option>
                                <option value="MUMBAI">MUMBAI</option>
                                <option value="DELHI">DELHI</option>
                            </select>
                        </div>
                    </div>

                    {/* Packages Grid */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {packages.map((pkg, index) => (
                            <div key={index} className="bg-graphite rounded-xl p-6 backdrop-blur-sm border border-gray-800 hover:border-gray-700 transition-all">

                                <div className="text-center mb-6">
                                    <h3 className="text-xl font-bold text-white mb-2">{pkg.name}</h3>
                                    <p className="text-3xl font-bold text-white">
                                        ₹{pkg.price}
                                        <span className="text-sm font-normal text-gray-400">/sq.ft (incl. GST)</span>
                                    </p>
                                </div>


                                <div className="mb-6">
                                    <div className="flex items-center justify-between mb-4">
                                        <h4 className="text-silver font-semibold">Designs & Drawings</h4>
                                        <FaPlus className=" text-silver" />
                                    </div>

                                </div>


                                {sections.map((section, idx) => (
                                    <div key={idx} className="py-3 border-t border-darkCoffee">
                                        <div className="flex items-center justify-between cursor-pointer">
                                            <h4 className="text-silver font-semibold">{section.title}</h4>
                                            <FaPlus className=" text-silver" />
                                        </div>
                                    </div>
                                ))}


                                <button className="mt-6 w-full bg-darkCoffee hover:bg-graphite text-white font-semibold py-3 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center gap-2">
                                    LET'S BUILD
                                    <FaArrowRight className="w-4 h-4" />
                                </button>
                            </div>
                        ))}
                    </div>



                </div>
            </div>

            {/* last */}
            <div className="relative isolate overflow-hidden bg-darkCoffee py-16 sm:py-24 lg:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">

                        <div className="max-w-xl lg:max-w-lg">
                            <h2 className="text-3xl  sm:text-5xl  font-extrabold  
            lg:text-left text-center custom-gradient">
                                Stay Updated on Our Construction Projects
                            </h2>
                            <p className="mt-4 text-md text-gray-300">
                                Receive insights into our latest projects, construction updates, and innovative solutions straight to your inbox. Join us as we build the future, one structure at a time.
                            </p>
                            <div className="mt-6 flex max-w-md gap-x-4">
                                <label htmlFor="email-address" className="sr-only">Email address</label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm/6"
                                    placeholder="Enter your email"
                                />
                                <button
                                    type="submit"
                                    className="flex-none rounded-md bg-graphite px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-silver focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>

                        {/* Right Section */}
                        <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
                            <div className="flex flex-col items-start">
                                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">

                                    <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 3v1.5M15 3v1.5M3 21v-4.5a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 1.5 1.5V21m-18 0h18" />
                                    </svg>
                                </div>
                                <dt className="mt-4 text-base font-semibold text-white">Monthly Project Updates</dt>
                                <dd className="mt-2 text-base text-gray-400">
                                    Discover the progress on our ongoing construction projects, from groundbreaking to finishing touches.
                                </dd>
                            </div>

                            <div className="flex flex-col items-start">
                                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">

                                    <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.75 5h14.5M4.75 10h14.5M4.75 15h14.5M12 20l4-4h-8l4 4z" />
                                    </svg>
                                </div>
                                <dt className="mt-4 text-base font-semibold text-white">Industry News & Insights</dt>
                                <dd className="mt-2 text-base text-gray-400">
                                    Get the latest industry trends, building innovations, and regulatory updates impacting construction.
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                {/* Background Gradient */}
                {/* <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
                    <div
                        className="aspect-[1155/678] w-[42.1875rem] bg-gradient-to-tr from-[#4B4B4B] to-[#4B4B4B] opacity-40"
                        style={{
                            clipPath: 'ellipse(50% 40% at 50% 40%)',
                        }}
                    ></div>
                </div> */}

            </div>

            <section className="py-24 px-8 bg-darkCoffee flex flex-col md:flex-row gap-12 w-full">
                <div className="flex flex-col text-left basis-1/2 ml-16 px-10">
                    <p className="text-3xl  sm:text-5xl  font-extrabold  
            lg:text-left text-center custom-gradient font-bold mb-4 leading-tight text-white custom-gradient">
                        Frequently Asked
                        <br />
                        Questions
                    </p>
                </div>


                <ul className="basis-1/3">
                    {faqs.map((faq, index) => (
                        <li key={index}>
                            <button
                                className="relative flex gap-2 items-center w-full py-5 text-silver text-left border-t md:text-md border-gunmetal" // Change here
                                aria-expanded={expandedIndex === index}
                                onClick={() => toggleFAQ(index)}
                            >
                                <span className="flex-1 text-silver">{faq.question}</span>
                                <svg className={`flex-shrink-0 w-4 h-4 ml-auto fill-current transition-transform duration-200 ease-out ${expandedIndex === index ? 'rotate-90' : ''}`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="7" width="16" height="2" rx="1"></rect>
                                    <rect y="7" width="16" height="2" rx="1" className="transform origin-center rotate-90"></rect>
                                </svg>
                            </button>
                            <div className={`transition-all duration-300 ease-in-out max-h-0 overflow-hidden ${expandedIndex === index ? 'max-h-40' : ''}`}>
                                <div className="pb-5 leading-relaxed">
                                    <div className="space-y-2 leading-relaxed">{faq.answer}</div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </section>






        </div>
    );
};

export default Home;
