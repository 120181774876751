// src/Header.js
import React, { useEffect, useState } from 'react';
import { FaPhoneAlt, FaEnvelope, FaBars, FaTimes, FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaBuilding, FaHome, FaClipboard, FaMap, FaLocationArrow, FaMapMarkerAlt, FaWhatsapp, FaYoutube } from 'react-icons/fa';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <header
            className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-charcoal shadow-lg' : 'bg-transparent'}`}
        >
            <div className="container mx-auto flex justify-between items-center py-4 px-6">
                <h1 className="text-3xl font-display text-silver bg-clip-text text-transparent custom-gradient">
                    SpaceMaster
                </h1>
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-silver focus:outline-none">
                        {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </button>
                </div>
                <nav className={`hidden md:flex gap-8 font-sans text-silver text-lg lg:text-base`}>
                    <a href="#home" className="hover:text-white transition duration-300">Home</a>
                    {/* <a href="#locations" className="hover:text-white transition duration-300">Locations</a> */}
                    <a href="#projects" className="hover:text-white transition duration-300">Our Projects</a>
                    <a href="#services" className="hover:text-white transition duration-300">Floor Plans</a>
                    <a href="#contact" className="hover:text-white transition duration-300">Contact Us</a>
                </nav>

                <div className="hidden md:flex gap-6">
                    <button className="border-2 border-silver bg-transparent font-semibold py-2 px-5 rounded-full transition-colors shadow-lg hover:shadow-xl custom-gradient">
                        Let's Build
                    </button>

                    <a href="tel:+1234567890" className="flex items-center gap-2 text-silver transition duration-300">
                        <FaPhoneAlt /> +123 456 7890
                    </a>

                </div>
            </div>
            {/* Mobile Menu */}

            <div className={`md:hidden ${isMenuOpen ? 'fixed inset-0 bg-darkCoffee p-6 flex flex-col items-start justify-start overflow-y-auto max-h-screen' : 'hidden'}`}>
                <button onClick={closeMenu} className="absolute top-4 right-4 text-silver">
                    <FaTimes size={24} />
                </button>
                <h1 className="text-4xl font-display text-silver bg-clip-text text-transparent custom-gradient">
                    SpaceMaster
                </h1>
                <p className="text-gunmetal mb-8 text-base mt-7">
                    At SpaceMaster Construction, we specialize in transforming visions into realities, creating spaces that inspire and endure.
                </p>

                <nav className="flex flex-col gap-10 text-silver text-xl font-sans mt-8">
                    <button className="border-2 border-silver bg-transparent font-semibold py-2 px-5 rounded-full transition-colors shadow-lg hover:shadow-xl custom-gradient">
                        Let's Build
                    </button>
                    <a href="#home" className="flex items-center gap-3 hover:text-white transition duration-300" onClick={closeMenu}>
                        <FaHome /> Home
                    </a>
                    <a href="#projects" className="flex items-center gap-3 hover:text-white transition duration-300" onClick={closeMenu}>
                        <FaClipboard /> Our Projects
                    </a>
                    <a href="#services" className="flex items-center gap-3 hover:text-white transition duration-300" onClick={closeMenu}>
                        <FaBuilding /> Floor Plans
                    </a>
                    <a href="#contact" className="flex items-center gap-3 hover:text-white transition duration-300" onClick={closeMenu}>
                        <FaEnvelope /> Contact Us
                    </a>

                    <div className="flex flex-col items-start gap-10">
                        <a href="tel:+1234567890" className="flex items-center gap-3 text-silver transition duration-300" onClick={closeMenu}>
                            <FaPhoneAlt /> +123 456 7890
                        </a>

                        <a href="https://goo.gl/maps/some-location-link" target="_blank" rel="noopener noreferrer" className="flex items-center gap-3 text-silver transition duration-300" onClick={closeMenu}>
                            <FaMapMarkerAlt /> Bangalore, India
                        </a>
                        <a href="mailto:info@spaceconstructions.com" className="flex items-center gap-3 text-silver transition duration-300" onClick={closeMenu}>
                            <FaEnvelope /> info@spaceconstructions.com
                        </a>
                    </div>

                    {/* Social Media Icons */}
                    <div className="flex gap-4 mt-4">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-silver hover:text-white transition duration-300">
                            <FaFacebook size={24} />
                        </a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-silver hover:text-white transition duration-300">
                            <FaTwitter size={24} />
                        </a>
                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-silver hover:text-white transition duration-300">
                            <FaLinkedin size={24} />
                        </a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-silver hover:text-white transition duration-300">
                            <FaInstagram size={24} />
                        </a>
                        <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer" className="text-silver hover:text-white transition duration-300">
                            <FaWhatsapp size={24} />
                        </a>
                        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-silver hover:text-white transition duration-300">
                            <FaYoutube size={24} />
                        </a>
                    </div>

                </nav>
            </div>



        </header>
    );
};

export default Header;
